export const environment = {
  baseUrl: 'https://knedp-admin-cms.demo.ukrohost.com',
  production: true,
  api: {
    url: 'https://knedp-admin-gateway.demo.ukrohost.com',
    routes: {
      api: 'api',
      auth: 'auth',
      admin: 'admin',
      front: 'front',
    },
  },
  front: {
    url: 'https://knedp-public-portal.demo.ukrohost.com',
  },
}
